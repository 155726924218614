import React, { FC, useRef } from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import BannerBase from 'components/BannerBase';

import { FinancialCalendarBannerProps } from './models';

import './FinancialCalendarBanner.scss';

const indexName = process.env.GATSBY_ALGOLIA_INDEX as string;

const SortBy = ({ items, refine, onChangeEventResults }) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <ul className="financial-calendar-banner__btn-holder" role="tablist">
      {items?.map((item) => (
        <li role="presentation">
          <button
            key={item.label}
            ref={btnRef}
            type="button"
            role="tab"
            aria-selected={item.isRefined}
            aria-controls="dates-events"
            className={classNames('financial-calendar-banner__btn btn', {
              'btn--pink': item.isRefined,
              'financial-calendar-banner__btn--no-refine': !item.isRefined,
            })}
            onClick={() => {
              refine(item.value);
              onChangeEventResults(item.value === `${indexName}-events`);
            }}
          >
            {item.isRefined ? onChangeEventResults(item.value === `${indexName}-events`) : null}
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

const CustomSortBy = connectSortBy(SortBy);

const FinancialCalendarBanner: FC<FinancialCalendarBannerProps> = ({ banner, onChangeEvents }) => (
  <section className="financial-calendar-banner">
    <Container>
      <Row>
        <Col xl="12" md="12" sm="12" className="coll--no-gutters">
          <BannerBase bannerClassName="financial-calendar-banner" headlineText={banner.title} />
          <CustomSortBy
            defaultRefinement={`${indexName}-events`}
            items={[
              { value: `${indexName}-events`, label: banner.upcomingEventsButtonText },
              { value: `${indexName}-events-date-desc`, label: banner.pastEventsButtonText },
            ]}
            onChangeEventResults={onChangeEvents}
            transformItems={(items) =>
              items.sort((a, b) => {
                const labelA = a.label.toUpperCase();
                const labelB = b.label.toUpperCase();
                if (labelA < labelB) {
                  return 1;
                }
                if (labelA > labelB) {
                  return -1;
                }

                return 0;
              })
            }
          />
        </Col>
      </Row>
    </Container>
  </section>
);

export default FinancialCalendarBanner;
