import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { EventItemLinkProps } from './models';

import './EventItemLink.scss';

const EventItemLink: FC<EventItemLinkProps> = ({ eventLink }) => {
  const link = [eventLink];

  return (
    <div className="events__item-event-link-container">
      <LinkItem
        link={link}
        linkClass="link link--pink events__item-event-btn"
        hasIcon
        isLink
        showTitle
      />
    </div>
  );
};

export default EventItemLink;
