import React, { FC } from 'react';
import { connectHits } from 'react-instantsearch-dom';

import EventsItem from './EventsItem';
import { EventsProps } from './models';

const Hits = ({ hits, titleText, isUpcoming, noEventsText, addToCalendarButtonText, lang }) => (
  <div className="events">
    {hits?.map((hit) => (
      <EventsItem
        key={hit.id}
        titleText={titleText}
        yearEvent={hit}
        isUpcoming={isUpcoming}
        noEventsText={noEventsText}
        addToCalendarButtonText={addToCalendarButtonText}
        lang={lang}
      />
    ))}
  </div>
);

const Event: FC<EventsProps> = connectHits(Hits);

export default Event;
