export const arraySplitter = (array, splitNum: number) => {
  const finalArray: any[][] = [];

  for (let i = 0; i < array?.length; i += splitNum) {
    const temporaryArray: any = array?.slice(i, i + splitNum);
    finalArray.push(temporaryArray);
  }

  return finalArray;
};

export const getCenter = (arr1: Array<number>, arr2: Array<number>) => {
  const maxArr1 = Math.max(...arr1);
  const minArr1 = Math.min(...arr1);
  const maxArr2 = Math.max(...arr2);
  const minArr2 = Math.min(...arr2);

  return {
    lat: (maxArr1 + minArr1) / 2,
    lng: (maxArr2 + minArr2) / 2,
  };
};

export const maxDifference = (arr: Array<number>) => {
  let maxDiff = 0;
  arr.forEach((item1) => {
    arr.forEach((item2) => {
      if (item1 >= item2) {
        if (item1 - item2 > maxDiff) maxDiff = item1 - item2;
      }
    });
  });

  return maxDiff;
};
