import React, { FC, useEffect, useState } from 'react';
import { CalendarOptions, ICalendar } from 'datebook';

import { Col } from 'layout';
import { formatDisplayDate, formatDisplayDateMonth } from 'utils/dateHandler';

import Arrow from '../../../../../common/icons/arrow-right.svg';
import EventItemLink from './EventItemLink';
import { EventItemProps } from './models';

import './EventItem.scss';

const EventItem: FC<EventItemProps> = ({
  title,
  startDate,
  endDate,
  displayOnlyMonth,
  location,
  description,
  upcomingEventLinks,
  isUpcoming,
  pressReleaseLink,
  addToCalendarButtonText,
  lang,
}) => {
  const [dateStart, setDateStart] = useState<string>('');
  const [dateEnd, setDateEnd] = useState<string>('');

  const saveEvent = (e) => {
    e.preventDefault();

    const config: CalendarOptions = {
      title,
      location: location || '',
      description: description || '',
      start: new Date(dateStart),
      end: dateEnd !== '' ? new Date(dateEnd) : undefined,
    };

    const icalendar = new ICalendar(config);

    icalendar.download();
  };

  useEffect(() => {
    if (displayOnlyMonth) {
      setDateStart(formatDisplayDateMonth(startDate, lang));
    } else {
      setDateStart(formatDisplayDate(startDate, lang));
      if (endDate) {
        setDateEnd(formatDisplayDate(endDate, lang));
      }
    }
  }, []);

  return (
    <Col xl="3" md="6" sm="12" className="events__item-col col--no-gutters">
      <div className="events__item-event">
        <span className="tag events__item-event-date">
          {`${dateStart} ${!displayOnlyMonth && endDate ? `- ${dateEnd}` : ''}`}
        </span>
        <h3 className="h6 events__item-event-title">{title}</h3>
        {isUpcoming ? (
          <div className="events__item-event-link-container">
            <button
              type="button"
              className="link link--pink events__item-event-calendar events__item-event-btn"
              onClick={(e) => saveEvent(e)}
            >
              <span>{addToCalendarButtonText}</span>
              <Arrow className="single-cta__icon" aria-hidden="true" />
            </button>
          </div>
        ) : null}
        {isUpcoming && upcomingEventLinks
          ? upcomingEventLinks?.map((eventLink) => (
              <EventItemLink eventLink={eventLink} key={eventLink.name + eventLink.url} />
            ))
          : null}
        {!isUpcoming && pressReleaseLink?.length ? (
          <EventItemLink eventLink={pressReleaseLink[0]} />
        ) : null}
      </div>
    </Col>
  );
};

export default EventItem;
