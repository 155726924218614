import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import InstantSearchContainer from 'common/InstantSearchContainer';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';
import FinancialCalendarBanner from 'components/FinancialCalendarBanner';

import FinancialCalendarBodyRenderer from './FinancialCalendarBodyRenderer';
import { FinancialCalendarPageTypes } from './models';

import './FinancialCalendarPage.scss';

const FinancialCalendarPage: FC<FinancialCalendarPageTypes.FinancialCalendarPageType> = ({
  data: { financialCalendarPage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    financialCalendarPage.seo;
  const { pageId, level, lang, title, banner, noResultsText, modules, url } = financialCalendarPage;

  const [isUpcoming, setIsUpcoming] = useState<boolean>(true);

  const changeEvent = (isUpcomingType) => {
    setIsUpcoming(isUpcomingType);
  };

  return (
    <Layout headerTransparent>
      <Seo
        {...{
          seo: financialCalendarPage.seo,
          openGraph: financialCalendarPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <InstantSearchContainer indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-events`}>
        <div className="banners">
          <Breadcrumbs level={level} />
          <FinancialCalendarBanner banner={banner} onChangeEvents={changeEvent} />
        </div>

        <div id="modules">
          <FinancialCalendarBodyRenderer
            modules={modules}
            pageId={pageId}
            isUpcoming={isUpcoming}
            noResultsText={noResultsText}
            lang={lang}
          />
        </div>
      </InstantSearchContainer>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    financialCalendarPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentFinancialCalendarBanner
      }
      noResultsText
      modules {
        ...FragmentFinancialCalendarModules
      }
    }
  }
`;

export default FinancialCalendarPage;
