import { getDisplayDate } from '@shared/types/handlers/datesHandler';

export const formatDisplayDate = (date: string, lang: string): string =>
  new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(getDisplayDate(date));

export const formatDisplayDateForInvestors = (date: string | undefined, lang: string): string => {
  if (!date) return '';

  return new Intl.DateTimeFormat(lang, {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
  }).format(getDisplayDate(date));
};

export const formatDisplayDateMonth = (date: string, lang: string): string =>
  new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'short',
  }).format(getDisplayDate(date));
