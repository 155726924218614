import React, { FC, useRef } from 'react';
import { Configure } from 'react-instantsearch-dom';

import Container from 'layout/Container';
import { EVENTS_PER_PAGE } from 'utils/constants';

import Events from './Events';
import EventsPagination from './EventsPagination';
import { DatesAndEventsProps } from './models';

import './DatesAndEvents.scss';

const DatesAndEvents: FC<DatesAndEventsProps> = ({
  module,
  pageId,
  isUpcoming,
  noResultsText,
  lang,
}) => {
  const eventsRef = useRef<HTMLDivElement>(null);

  const yearCondition = isUpcoming
    ? `year >= ${new Date().getFullYear()}`
    : `year <= ${new Date().getFullYear()}`;

  const handleScrollToView = () => {
    if (eventsRef && eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section ref={eventsRef} id="dates-events" className="section dates-and-events">
      <div className="dates-and-events__holder">
        <Configure
          hitsPerPage={EVENTS_PER_PAGE}
          numericFilters={[`parentId = ${pageId}`, yearCondition]}
          filters={`lang: "${lang}"`}
        />
        <Container>
          <Events
            titleText={module.monthsText}
            isUpcoming={isUpcoming}
            noEventsText={noResultsText}
            addToCalendarButtonText={module.addToCalendarButtonText}
            lang={lang}
          />
          <EventsPagination
            nextPage={module.nextButtonText}
            prevPage={module.previousButtonText}
            scrollToView={handleScrollToView}
          />
        </Container>
      </div>
    </section>
  );
};

export default DatesAndEvents;
