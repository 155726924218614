import React, { FC, ReactElement } from 'react';

import DatesAndEvents from 'components/DatesAndEvents';
import HeadingAndText from 'components/HeadingAndText';

import { FinancialCalendarBodyRendererProps } from './models';

const Components = {
  'Heading And Text': HeadingAndText,
  'Dates And Events': DatesAndEvents,
};

const FinancialCalendarBodyRenderer: FC<FinancialCalendarBodyRendererProps> = ({
  modules,
  pageId,
  isUpcoming,
  noResultsText,
  lang,
}): ReactElement => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      if (block.structure === 'Heading And Text') {
        return <Component key={`${block.structure}${index}`} module={block.module} />;
      }

      return (
        <Component
          key={`${block.structure}${index}`}
          module={block.module}
          pageId={pageId}
          isUpcoming={isUpcoming}
          noResultsText={noResultsText}
          lang={lang}
        />
      );
    })}
  </>
);

export default FinancialCalendarBodyRenderer;
