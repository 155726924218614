import React, { FC } from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import classNames from 'classnames';

import Arrow from '../../../common/icons/arrow-right.svg';
import { EventsPaginationProps, PaginationProps } from './models';

import './EventsPagination.scss';

const Pagination: FC<PaginationProps> = ({
  currentRefinement,
  nbPages,
  refine,
  nextPage,
  prevPage,
  scrollToView,
}) => {
  const handleArrowPagination = (event, currentPage, numberOfPages, prev) => {
    event.preventDefault();
    scrollToView();

    if (prev) {
      refine(currentPage === 1 ? currentPage : currentPage - 1);
    } else {
      refine(numberOfPages === currentPage ? currentPage : currentPage + 1);
    }
  };

  return (
    <div className="events-pagination">
      <button
        type="button"
        className={classNames('events-pagination__btn events-pagination__btn--prev', {
          'events-pagination__btn--disabled': currentRefinement === 1,
        })}
        onClick={(event) => {
          handleArrowPagination(event, currentRefinement, nbPages, true);
        }}
      >
        <Arrow className="events-pagination__icon" aria-hidden="true" />
        <span className="breadcrumb events-pagination__btn-text">{prevPage}</span>
      </button>
      <ul className="events-pagination__list">
        {new Array(nbPages).fill(null).map((_, index) => {
          const page = index + 1;

          return (
            <li
              key={page}
              className={classNames('events-pagination__item', {
                'events-pagination__item--active': currentRefinement === page,
              })}
            >
              <button
                className="events-pagination__page breadcrumb"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  scrollToView();
                  refine(page);
                }}
              >
                {page}
              </button>
            </li>
          );
        })}
      </ul>
      <button
        type="button"
        className={classNames('events-pagination__btn', {
          'events-pagination__btn--disabled': currentRefinement === nbPages,
        })}
        onClick={(event) => {
          handleArrowPagination(event, currentRefinement, nbPages, false);
        }}
      >
        <span className="breadcrumb events-pagination__btn-text">{nextPage}</span>
        <Arrow className="events-pagination__icon" aria-hidden="true" />
      </button>
    </div>
  );
};
const CustomPagination = connectPagination(Pagination);

const EventsPagination: FC<EventsPaginationProps> = ({ nextPage, prevPage, scrollToView }) => (
  <>
    <CustomPagination nextPage={nextPage} prevPage={prevPage} scrollToView={scrollToView} />
  </>
);

export default EventsPagination;
