import React, { FC, useEffect, useState } from 'react';
import { getDisplayDate } from '@shared/types/handlers/datesHandler';
import { debounce } from 'lodash';

import { Row } from 'layout';
import { arraySplitter } from 'utils/arraysHandler';
import { isBrowser } from 'utils/browser';
import { DESKTOP_DEVICE, TABLET_DEVICE } from 'utils/constants';

import EventItem from './EventItem';
import { EventItemProps } from './EventItem/models';
import { EventsItemProps } from './models';

import './EventsItem.scss';

const EventsItem: FC<EventsItemProps> = ({
  titleText,
  yearEvent,
  isUpcoming,
  noEventsText,
  addToCalendarButtonText,
  lang,
}) => {
  const [screen, setScreen] = useState<number>();
  const [eventsItem, setEventsItem] = useState<EventItemProps[][]>([]);

  const screenHandler = (data) => {
    if (screen && screen >= DESKTOP_DEVICE) {
      setEventsItem(arraySplitter(data, 4));
    }
    if (screen && screen >= TABLET_DEVICE && screen < DESKTOP_DEVICE) {
      setEventsItem(arraySplitter(data, 2));
    }
    if (screen && screen < TABLET_DEVICE) {
      setEventsItem(arraySplitter(data, 1));
    }
  };

  const handleResize = debounce(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }
  }, 100);

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const sortedEvents = yearEvent.events.sort((a, b) => {
      const c = getDisplayDate(a.startDate);
      const d = getDisplayDate(b.startDate);

      return c.getTime() - d.getTime();
    });

    let filteredAndSorted = sortedEvents;

    if (yearEvent.year === new Date().getFullYear()) {
      filteredAndSorted = sortedEvents.filter((date) =>
        isUpcoming
          ? getDisplayDate(date.startDate) > new Date()
          : getDisplayDate(date.startDate) < new Date()
      );
    }

    screenHandler(filteredAndSorted);
  }, [screen, yearEvent]);

  return (
    <div className="events__item">
      <h2 className="h4 events__item-title">{`${titleText} ${yearEvent.year}`}</h2>
      {eventsItem.length ? (
        eventsItem.map((events) => (
          <Row key={events.map((item) => `${item.startDate.toString()}-${item.title}`).join(`-`)}>
            {events?.map((eventItem) => (
              <EventItem
                key={`${eventItem.title}-${eventItem.startDate.toString()}`}
                title={eventItem.title}
                startDate={eventItem.startDate}
                endDate={eventItem.endDate}
                displayOnlyMonth={eventItem.displayOnlyMonth}
                location={eventItem.location}
                description={eventItem.description}
                upcomingEventLinks={eventItem.upcomingEventLinks}
                pressReleaseLink={eventItem.pressReleaseLink}
                isUpcoming={isUpcoming}
                addToCalendarButtonText={addToCalendarButtonText}
                lang={lang}
              />
            ))}
          </Row>
        ))
      ) : (
        <p className="events__text-no-events">{noEventsText}</p>
      )}
    </div>
  );
};

export default EventsItem;
