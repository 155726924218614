export const getDisplayDate = (date: string): Date => {
  const t = date.split(/[- :]/);
  const month = (parseInt(t[1], 10) - 1).toString();
  const dt = new Date(
    parseInt(t[0], 10),
    parseInt(month, 10),
    parseInt(t[2], 10),
    parseInt(t[3], 10),
    parseInt(t[4], 10),
    parseInt(t[5], 10)
  );

  return new Date(dt);
};
